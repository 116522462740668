import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = 'https://lockout-tagout-shop.adapts2ls.online/api/';
  token = '';

  constructor(private httpClient: HttpClient) { }

  read(endpoint, tokenIsRequired = true) {
	this.token = localStorage.getItem('token');

    let self = this;
	let url = '';

		if (tokenIsRequired) {
			url = self.baseUrl + endpoint + '&token=' + self.token;
		} else {
			url = self.baseUrl + endpoint;
		}

		return new Promise(function(resolve, reject) {
			self.httpClient.get(url)
			.subscribe(data => 
			{
				let response: any = data;			
				resolve(response);
			},
			error => 
			{
				reject(error);
			});
		});	
  }

}
