import { Component } from '@angular/core';
import { TranslationsService } from './services/translations.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private translationsService: TranslationsService) {}

  ngOnInit() {
    this.translationsService.setTranslations();
  }
}
