import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  translations = {};

  constructor(private apiService: ApiService) { }

  setTranslations() {
    let self = this;

    this.apiService.read('language', true).then(function(response) {
      self.translations = response;
    }).catch(function(err) {
      console.error(err);
    });
  }

  getTranslation(key) {
    if (this.translations[key] != null) {
      return this.translations[key];
    }
  }
  
}
